import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Stack, ComponentWrapper, Button } from 'ascential-horizon'
import getThemeConfig from '../providers/config/themeConfig'
import { useIdentity } from '../providers/identityContext'
import { windowExists } from '../utils'
import Layout from '../Layout'

function NotFoundPage() {
  const { loggedIn, user } = useIdentity()
  const data = useStaticQuery(graphql`
    query {
      header: contentfulNav(instanceName: { eq: "Main Menu" }) {
        ...navQuery
      }
      headerAuth: contentfulNav(instanceName: { eq: "Main Menu Auth" }) {
        ...navQuery
      }
      footer: contentfulFooter(instanceName: { eq: "Main Footer" }) {
        ...footerQuery
      }
      footerNav: contentfulFooter(instanceName: { eq: "Footer" }) {
        ...footerQuery
      }
    }
  `)

  const {
    footer: { type },
    notFound: { cta, message, title },
  } = getThemeConfig()

  const { footer, footerNav, header, headerAuth } = data

  const getHeaderProps = () => {
    if (user?.loading) {
      return <></>
    }
    return loggedIn && headerAuth ? headerAuth : header
  }

  const footerProps = type === 'menu' ? footerNav : footer

  const layoutProps = {
    header: getHeaderProps(),
    footer: footerProps,
    pageType: '/',
    slug: '/404',
  }

  /**
   * Adds a simple check to see if `windowExists`, ultimately preventing
   * the cursed 404 flash[0] when deployed somewhere like Netlify. Huge thanks to
   * Sam Featherstone (GitHub @samburgers) for the suggestion.[1]
   *
   * [0]: https://github.com/gatsbyjs/gatsby/issues/5329
   * [1]: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119
   */

  return (
    windowExists && (
      <Layout {...layoutProps}>
        <ComponentWrapper>
          <Stack space="xlarge">
            <div>
              <h2>{title}</h2>
              <p>{message}</p>
              {cta && (
                <Button variant="primary" url={cta.url}>
                  {cta.label}
                </Button>
              )}
            </div>
          </Stack>
        </ComponentWrapper>
      </Layout>
    )
  )
}

export default NotFoundPage
